import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { SWANConstants } from '@common/SWANConstants';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { BaseController } from 'src/app/base.controller';

export class AddSiteDialogController extends BaseController {
  public newName: string;
  public nameExists = false;
  public maximumDate: Date;
  public minimumDate: Date;
  public effectiveDate: Date;
  public assets = [] as fuse.siteProfileDto[];

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _accountService: AccountsService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _languageService: LanguageService;

  private accountDetail: fuse.accountDetail;
  private isSuperUser: boolean;
  public isSubmitting = false;
  public groupId: number;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AccountsService: AccountsService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    assets: fuse.siteProfileDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._accountService = AccountsService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.assets = assets;
    this.isSuperUser = PermissionService.isSuperUser;
    this._dupeHandlerService = DupeHandlerService;

    this._dupeHandlerService.setDuplicateType('Site (IMU)');
  }

  $onInit() {
    const context = LocalStorageUtils.contextData;

    this.groupId = context.groupId;
    this.maximumDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();
    this.effectiveDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.getAccountDetail().then(
      () => {
        if (!this.isSuperUser) {
          const currentYear = this.maximumDate.getFullYear();
          const currentMonth = this.maximumDate.getMonth();
          const seasonStartDate = new Date(
            this.accountDetail.irrigationSeasonStartMonth - 1 > currentMonth ? currentYear - 1 : currentYear,
            this.accountDetail.irrigationSeasonStartMonth - 1,
            1,
          );

          this.minimumDate = seasonStartDate.addYears(-1);
        } else {
          this.minimumDate = null;
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  public closeDialog() {
    if (this.scope['addSiteForm'].$dirty) {
      const confirm = this._languageService.closeDialog();

      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public checkSiteName() {
    if (this.newName) {
      if (this.assets.some((a) => a.name.toLowerCase() == this.newName.toLowerCase())) {
        this.nameExists = true;
      } else {
        this.nameExists = false;
      }
    } else {
      this.nameExists = false;
    }
  }

  public submit() {
    this.isSubmitting = true;

    const siteClassId = SWANConstants.assetClasses.find((a) => a.name == AssetClassNameEnum.SiteIMU).id;
    const newSite = this.addAssetEntity(this.newName, siteClassId);

    this.addSiteSettings(newSite);

    this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
      () => {
        LocalStorageUtils.updateContextData((context) => {
          context.assetId = newSite.AssetId;
          context.siteId = newSite.AssetId;
        });

        this._mdDialog.hide();
        this._state.go('app.account.sites.detail', { id: newSite.AssetId, viewMap: true });
      },
      (saveFailed) => {
        this._dupeHandlerService.showError(saveFailed);
        this.isSubmitting = false;
      },
    );
  }

  private addAssetEntity(newName: string, siteClassId: number): Asset {
    const assetInitialValues = {
      OwnerAccountId: this.accountId,
      AssetClassId: siteClassId,
      Latitude: this.accountDetail.defaultLatitude, //Logic in site index controller that doesn't display map if set to orig default of -26,131
      Longitude: this.accountDetail.defaultLongitude,
      Elevation: 0,
      Name: newName,
      Status: 'Active',
    };

    const assetType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
    const newAsset = assetType.createEntity(assetInitialValues) as Asset;
    this.entityManager.addEntity(newAsset);

    const siteType = this.entityManager.metadataStore.getEntityType('Site') as breeze.EntityType;

    const newSite = siteType.createEntity({
      AssetId: newAsset.AssetId,
      CropId: this.accountDetail.fallowCropId,
    });

    this.entityManager.addEntity(newSite);

    return newSite as Asset;
  }

  private addSiteSettings(newSite: Asset) {
    const effectiveDate = this.effectiveDate.clone();

    const siteCropInitialValues = this._dataEntityService.getDefault('SiteSettingsCrop', effectiveDate) as any;
    const siteCropType = this.entityManager.metadataStore.getEntityType('SiteSettingsCrop') as breeze.EntityType;
    siteCropInitialValues.AssetId = newSite.AssetId;
    siteCropInitialValues.CropId = this.accountDetail.fallowCropId;
    const newSiteCropSettings = siteCropType.createEntity(siteCropInitialValues);
    this.entityManager.addEntity(newSiteCropSettings);

    const siteNutrientsInitialValues = this._dataEntityService.getDefault('SiteSettingsNutrients', effectiveDate);
    const siteNutrientsType = this.entityManager.metadataStore.getEntityType('SiteSettingsNutrients') as breeze.EntityType;
    siteNutrientsInitialValues.AssetId = newSite.AssetId;
    const newSiteNutrientsSettings = siteNutrientsType.createEntity(siteNutrientsInitialValues);
    this.entityManager.addEntity(newSiteNutrientsSettings);

    const siteSoilInitialValues = this._dataEntityService.getDefault('SiteSettingsSoil', effectiveDate) as any;
    const siteSoilType = this.entityManager.metadataStore.getEntityType('SiteSettingsSoil') as breeze.EntityType;
    siteSoilInitialValues.AssetId = newSite.AssetId;
    siteSoilInitialValues.WorkingSoilDepthTop_mm = 0;
    const newSiteSoilSettings = siteSoilType.createEntity(siteSoilInitialValues);
    this.entityManager.addEntity(newSiteSoilSettings);

    const siteWaterInitialValues = this._dataEntityService.getDefault('SiteSettingsWater', effectiveDate);
    const siteWaterType = this.entityManager.metadataStore.getEntityType('SiteSettingsWater') as breeze.EntityType;
    siteWaterInitialValues.AssetId = newSite.AssetId;
    const newSiteWaterSettings = siteWaterType.createEntity(siteWaterInitialValues);
    this.entityManager.addEntity(newSiteWaterSettings);
  }

  private getAccountDetail(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._accountService.getSystemAccountById(this.accountId).then(
      (result) => {
        this.accountDetail = result;
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }
}

angular.module('app.account').controller('AddSiteDialogController', AddSiteDialogController);
