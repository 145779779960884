import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class SiteHistoryChartHelpDialogController extends BaseController {
  public _fluidDepthNormalUnit = undefined;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this._fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('SiteHistoryChartHelpDialogController', SiteHistoryChartHelpDialogController);
