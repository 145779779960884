import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { TableControl } from '@common/helpers/TableControl';
import { DataEntityService } from '@services/data-entity.service';
import { GroupSiteService } from '@services/group-site.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { AddSiteDialogController } from './addsite-dialog.controller';
import { BaseController } from 'src/app/base.controller';

interface ISiteRow {
  sites: fuse.siteProfileDto[];
  subInfos: any;
}

export class SitesController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _window: angular.IWindowService;
  private _dataEntityService: DataEntityService;
  private _groupSiteService: GroupSiteService;
  private _localStorageService: LocalStorageService;

  public isSuperUser = false;
  public areaNormalUnit = undefined;
  public iActive = 0;
  public iSuspended = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;
  public showSuspended = false;
  public nameFilter = '';
  public filteredSites: fuse.siteProfileDto[];
  public displayedSites: fuse.siteProfileDto[];
  public tableControl = new TableControl();
  public siteRows: ISiteRow = {} as ISiteRow;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $window: angular.IWindowService,
    DataEntityService: DataEntityService,
    GroupSiteService: GroupSiteService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._window = $window;
    this._dataEntityService = DataEntityService;
    this._groupSiteService = GroupSiteService;
    this._localStorageService = LocalStorageService;

    this.isSuperUser = this.permissionService.isSuperUser;
    this.areaNormalUnit = UnitOfMeasureService.getUnitLabel('Area', unitSizes.normal);

    if (GroupSiteService.getKeepFilter() == true) {
      this.nameFilter = GroupSiteService.getNameFilter();
      this.showActive = GroupSiteService.getActiveState();
      this.showArchived = GroupSiteService.getArchivedState();
      this.showSuspended = GroupSiteService.getSuspendedState();
      GroupSiteService.setKeepFilter(false);
    } else {
      GroupSiteService.setNameFilter(this.nameFilter);
      GroupSiteService.setActiveState(this.showActive);
      GroupSiteService.setArchivedState(this.showArchived);
      GroupSiteService.setSuspendedState(this.showSuspended);
    }
  }

  public openSitesBulkImport() {
     this._window.location.href = `/react/accounts/${this.permissionService.accountId}/sites-bulk-import`;
  }

  public openAddSiteDialog() {
    this._mdDialog
      .show({
        controller: AddSiteDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/sites/addsite-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          assets: this.siteRows.sites,
        },
      })
      .then(() => {});
  }

  $onInit() {
    this._localStorageService.initAccountList();
    this.loadGroupData();
  }

  // All data that will need reloading on changing selected parent group
  public loadGroupData() {
    this.siteRows.sites = [];
    this.siteRows.subInfos = {};
    this.setWatches();
    this._dataEntityService.reset();
    this.getAccountSiteProfiles();
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.tableControl.pageSize', 'vm.tableControl.currentPage', 'vm.nameFilter'], () => {
      if (this.siteRows.sites.length) {
        this.filterSites();
      }
    });
  }

  private updateTable() {
    const siteIds = this.displayedSites.map((a) => a.siteId);

    // Don't fetch if already has Site Summary SubInfo
    const filteredAssetIds = siteIds.filter((assetId) => {
      return !this.siteRows.subInfos[assetId] || !this.siteRows.subInfos[assetId].soilmoisture;
    });

    if (filteredAssetIds.length) {
      const subInfoParams = { accountId: this.accountId, assetIds: filteredAssetIds } as fuse.accountSiteSummary2Dto;
      // Fetch group health readings, soil moisture and water usages
      this._http
        .post(CommonHelper.getApiUrl('user/AccountSiteSummary2'), JSON.stringify(subInfoParams))
        .then((response) => {
          const data = response.data as fuse.accountSiteSummaryModel;
          if (data.sites) {
            data.sites.forEach((subInfo) => {
              this.siteRows.subInfos[subInfo.id] = {};
              this.siteRows.subInfos[subInfo.id].soilmoisture = subInfo.soilmoisture;
              this.siteRows.subInfos[subInfo.id].waterusage_status = subInfo.waterusage_status;
            });
          }
        });
    }
  }

  public getIconClass(subInfo: fuse.soilMoistureCounts) {
    let colour = '';
    let icon = '';

    if (subInfo.high) {
      colour = 'soilTargetWet';
      icon = 'icon-arrow-up-bold-circle';
    }

    if (subInfo.ok) {
      icon = 'icon-checkbox-marked-circle';
      colour = 'soilTargetOk';
    }

    if (subInfo.low) {
      icon = 'icon-arrow-down-bold-circle';
      colour = 'soilTargetDry';
    }

    if (subInfo.warning) {
      icon = 'icon-alert-circle';
    }
    return `${icon} ${colour}`;
  }

  public openSMIconsHelpDialog() {
    this._mdDialog
      .show({
        controller: SitesController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/sites/SMIconsHelp-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          keyword: '[keyword]',
        },
      })
      .then(() => {});
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public activeStatusClass(status: string) {
    if (status === 'Active') {
      return 'icon-checkbox-marked-circle swanGreen';
    }
    if (status === 'Suspended') {
      return 'icon-minus-circle swanOrange';
    }
    if (status === 'Archived') {
      return 'icon-cancel red-500-fg';
    }
    return 'icon-checkbox-marked-circle swanGreen';
  }

  private filterSites() {
    this._groupSiteService.setNameFilter(this.nameFilter);
    this._groupSiteService.setActiveState(this.showActive);
    this._groupSiteService.setArchivedState(this.showArchived);
    this._groupSiteService.setSuspendedState(this.showSuspended);

    if (this.siteRows.sites) {
      this.filteredSites = ArrayUtils.sortByString(
        this.siteRows.sites.filter((x) =>
          x.name.toLowerCase().includes(this.nameFilter.toLowerCase())
          && (this.showActive || x.status !== 'Active')
          && (this.showArchived || x.status !== 'Archived')
          && (this.showSuspended || x.status !== 'Suspended')
        ),
        (x) => x.name
      );
    }

    this.displayedSites = this.filteredSites.slice(
      (this.tableControl.currentPage - 1) * this.tableControl.pageSize,
      this.tableControl.currentPage * this.tableControl.pageSize,
    );

    this.updateTable();
  }

  private getAccountSiteProfiles() {
    this.iActive = 0;
    this.iSuspended = 0;
    this.iArchived = 0;
    this._http.get(CommonHelper.getApiUrl('site/getAccountSiteProfiles')).then((response) => {
      this.siteRows.sites = response.data as fuse.siteProfileDto[];
      if (this.siteRows.sites != null) {
        this.siteRows.sites.forEach((site) => {
          if (site.status == 'Suspended') this.iSuspended++;
          else if (site.status == 'Archived') this.iArchived++;
          else this.iActive++;
        });
        this.filterSites();
      }
    });
  }

  public gotoSiteDetail(siteId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.siteId = siteId;
      context.assetId = siteId;
    });

    this._state.go('app.account.sites.detail', { id: siteId, viewSchedule: true });
  }

  public toggleButton(button: string): void {
    if (button == 'Active') this.showActive = !this.showActive;
    if (button == 'Suspended') this.showSuspended = !this.showSuspended;
    if (button == 'Archived') this.showArchived = !this.showArchived;
    this.filterSites();
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }
}

angular.module('app.account').controller('SitesController', SitesController);
