// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-water-applied .grouped {
  font-size: 12px;
  margin: 2px 0;
  padding: 3px 5px 3px 5px;
  text-transform: none;
  font-weight: 400;
  min-width: 20px;
}
#site-water-applied .mini-button {
  border-right: 1px solid rgba(230, 230, 230, 0.96);
  height: 20px;
  line-height: 6px;
}
#site-water-applied .md-button.md-mini-fab {
  padding: 0px;
  margin: 4px;
  width: 24px;
  height: 24px;
}
#site-water-applied md-checkbox.red {
  margin-bottom: 0;
}
#site-water-applied md-checkbox.red.md-checked .md-icon {
  background-color: rgba(255, 0, 0, 0.5);
}
#site-water-applied #site-daily-summary-table md-input-container {
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  width: 120px;
}
#site-water-applied #site-daily-summary-table md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/waterTabs/site-water-applied.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,aAAA;EACA,wBAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;AAAR;AAEI;EACI,iDAAA;EACA,YAAA;EACA,gBAAA;AAAR;AAEI;EACI,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEI;EACI,gBAAA;AAAR;AAEY;EACI,sCAAA;AAAhB;AAKQ;EACI,SAAA;EACA,UAAA;EACA,sBAAA;EACA,YAAA;AAHZ;AAIY;EAEI,0BAAA;AAHhB","sourcesContent":["#site-water-applied {\r\n    .grouped {\r\n        font-size: 12px;\r\n        margin: 2px 0;\r\n        padding: 3px 5px 3px 5px;\r\n        text-transform: none;\r\n        font-weight: 400;\r\n        min-width: 20px;\r\n    }\r\n    .mini-button {\r\n        border-right: 1px solid rgba(230, 230, 230, 0.96);\r\n        height: 20px;\r\n        line-height: 6px;\r\n    }\r\n    .md-button.md-mini-fab {\r\n        padding: 0px;\r\n        margin: 4px;\r\n        width: 24px;\r\n        height: 24px;\r\n    }\r\n    md-checkbox.red {\r\n        margin-bottom: 0;\r\n        &.md-checked {\r\n            .md-icon {\r\n                background-color: rgba(255, 0, 0, 0.5);\r\n            }\r\n        }\r\n    }\r\n    #site-daily-summary-table {\r\n        md-input-container {\r\n            margin: 0;\r\n            padding: 0;\r\n            vertical-align: bottom;\r\n            width: 120px;\r\n            .md-errors-spacer {\r\n                //display: inline;\r\n                min-height: 0px !important;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
