// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-history-chart-help table {
  border: solid 1px;
  border-collapse: collapse;
}
#site-history-chart-help table tr {
  height: 30px;
}
#site-history-chart-help table tr th {
  border: solid 1px;
  padding-left: 4px;
}
#site-history-chart-help table tr td {
  padding-left: 4px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 11px;
  border: solid 1px;
  min-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/waterTabs/siteHistoryChartHelp.-dialog.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;EACA,yBAAA;AADR;AAEQ;EACI,YAAA;AAAZ;AACY;EACI,iBAAA;EACA,iBAAA;AAChB;AACY;EACI,iBAAA;EACA,gDAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAChB","sourcesContent":["\r\n#site-history-chart-help {\r\n    table {\r\n        border: solid 1px;\r\n        border-collapse: collapse;\r\n        tr {\r\n            height: 30px;\r\n            th {\r\n                border: solid 1px;\r\n                padding-left: 4px;\r\n            }\r\n            td {\r\n                padding-left: 4px;\r\n                font-family: Verdana, Geneva, Tahoma, sans-serif;\r\n                font-size: 11px;\r\n                border: solid 1px;\r\n                min-width: 200px;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
