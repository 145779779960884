// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account-sites md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#account-sites md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#account-sites md-input-container .md-input {
  color: white;
  border-color: white;
  width: 80px;
}
#account-sites .hover:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/sites/sites.scss"],"names":[],"mappings":"AAII;EACI,eAAA;EACA,UAAA;AAHR;AAIQ;EAEI,0BAAA;AAHZ;AAKQ;EACI,YAAA;EACA,mBAAA;EACA,WAAA;AAHZ;AAMI;EACI,eAAA;AAJR","sourcesContent":["\r\n\r\n#account-sites {\r\n\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n            width: 80px;\r\n        }\r\n    }\r\n    .hover:hover {\r\n        cursor: pointer;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
