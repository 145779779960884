import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IHistoryChartParams } from '@common/models/interfaces';
import { SiteIrrigationHistoryConverter } from '@common/unitconverters/site-irrigation-history-converter';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { ColourSetService } from '@services/colour-set.service';
import { DayNumberService } from '@services/day-number.service';
import { SiteHistoryChartHelpDialogController } from './siteHistoryChartHelp-dialog.controller';
import { BaseController } from 'src/app/base.controller';

class SiteWaterHistoryComponent implements angular.IComponentOptions {
  bindings = {
    siteId: '<',
    siteName: '<',
    reloadCount: '<',
    refreshCount: '<',
    selectedProbeId: '<',
    forecastRainUsePct: '<',
  };
  controller = SiteWaterHistoryController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/waterTabs/site-water-history.html';
}

class ProbeNode {
  description: string;
  isSelected: boolean;
  name: string;
  nodes: ProbeNode[];
  probeId: number;
}

interface IPlotExtra{
  alphaField: string;
  bullet: string;
  bulletBorderAlpha: number;
  bulletColor: string;
  clustered: boolean;
  columnWidth: number;
  connect: boolean;
  dashLength: number;
  fillAlphas: number;
  fillColors: string[];
  fillToGraph: AmCharts.AmGraph;
  labelTitle: string;
  lineAlpha: number;
  lineColor: string;
  markerType: string;
  negativeFillAlphas: number;
  negativeFillColors: string[];
  showBalloon: boolean;
  translated: boolean;
  type: string;
  valueAxis: AmCharts.ValueAxis;
}

class SiteWaterHistoryController extends BaseController {
  private _http: angular.IHttpService;
  private _interval: angular.IIntervalService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _window: angular.IWindowService;
  private _colourSetService: ColourSetService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public minDate = SWANConstants.MinDate;

  private siteId: number;
  private siteName: string;
  public siteHistoryFromData: Date;
  private siteHistoryChartDataProvider: fuse.siteIrrigationHistoryDto[];
  private convertedsiteHistoryChartDataProvider: fuse.siteIrrigationHistoryDto[];
  private siteProbesHistoryDataList = [] as fuse.siteProbeHistoryDataDto[];
  private siteHistoryChart: AmCharts.AmSerialChart;
  private siteHistoryChartOption: AmCharts.AmSerialChart;
  public historyChartParams: IHistoryChartParams;
  private isHistoryChartZoomed = false;
  private historyChartZoomStartdDate: Date;
  private historyChartZoomEndDate: Date;
  private goBackDays = 50;
  private adjustedTodayDayNumber: number;
  public probes = [] as ProbeNode[];
  private selectedProbeId: number;
  private _fluidDepthNormalUnit: string;
  private _soilDepthNormalUnit: string;
  private fluidDepthNormalUnitDecimal: number;
  public forecastRainUsePct: number;

  constructor(
    $http: angular.IHttpService,
    $interval: angular.IIntervalService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    $window: angular.IWindowService,
    ColourSetService: ColourSetService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._interval = $interval;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._window = $window;
    this._colourSetService = ColourSetService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this._fluidDepthNormalUnit = this._unitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.fluidDepthNormalUnitDecimal = this._unitOfMeasureService.getDecimalPlaces('Fluid Depth', unitSizes.normal);
    this._soilDepthNormalUnit = this._unitOfMeasureService.getUnitLabel('Soil Depth', unitSizes.normal);

    this.getHistoryChartParams();

    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.siteHistoryFromData = this._dayNumberService.convertDayNumberToLocaleDate(this.adjustedTodayDayNumber).addYears(-1).addDays(7);

    angular.element($window).on('resize', () => {
      $timeout(() => {
        if (this.siteHistoryChart) {
          this.siteHistoryChart.validateNow();
        }
      }, 0);
    });
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      if (!this.siteHistoryChartDataProvider) {
        const promises = [] as angular.IPromise<void>[];

        promises.push(this.getSiteIrrigationHistory());
        promises.push(this.getSiteProbeHistoryData());

        this._q.all(promises).then(() => {
          this.defineSiteHistoryChart();

          const historyChartTimer = this._interval(() => {
            this.siteHistoryChart = AmCharts.makeChart('site-history-chart', this.siteHistoryChartOption) as AmCharts.AmSerialChart;

            if (this.siteHistoryChart['chartRendered']) {
              this._interval.cancel(historyChartTimer);

              if (this.siteHistoryChartDataProvider?.length) {
                this.convertedsiteHistoryChartDataProvider = SiteIrrigationHistoryConverter.convert(
                  this._unitOfMeasureService,
                  this.siteHistoryChartDataProvider,
                  unitSizes.normal,
                );
                this.addProbesHistoryTo(this.convertedsiteHistoryChartDataProvider);
                this.siteHistoryChart.dataProvider = this.convertedsiteHistoryChartDataProvider;
                this.addGuideToday(this.siteHistoryChart);
                this.siteHistoryChart.validateData();
              } else {
                if (this.siteHistoryChart != null) {
                  this.siteHistoryChart.clear();
                  this.siteHistoryChart = null;
                }

                this._languageService.warning('AC.SITE.MSG.NO_HISTORICAL_DATA');
              }
            }
          }, 500);
        });
      }
    }

    if (changes.refreshCount?.currentValue > 0) {
      if (this.siteHistoryChart) {
        this.siteHistoryChart.validateNow();
      }
    }

    if (changes.selectedProbeId) {
      this.selectedProbeId = changes.selectedProbeId.currentValue;
      this.createProbeList();
      this.defineSiteHistoryChart();

      if (this.siteHistoryChartDataProvider != null) {
        this.showSiteHistoryChart();
      }
    }
  }

  $onDestroy(): void {
    angular.element(this._window).off('resize');
  }

  private getHistoryChartParams() {
    this.historyChartParams = this._localStorageService.get('historyChartParams');

    if (this.historyChartParams == null) {
      this.historyChartParams = {} as IHistoryChartParams;
    }

    if (this.historyChartParams.soilMoisturePercent == null) {
      this.historyChartParams.soilMoisturePercent = true;
      this._localStorageService.set('historyChartParams', this.historyChartParams);
    }
  }

  private addProbesHistoryTo(data: fuse.siteIrrigationHistoryDto[]) {
    if (this.siteProbesHistoryDataList?.length) {
      let index = 0;

      this.siteProbesHistoryDataList.forEach((probeSetting) => {
        probeSetting.depthInfos.forEach((depthInfo) => {
          const oriDepthValues = [];

          probeSetting.probeHistoryDataList.forEach((item) => {
            const depthValue = item.depthMoistures.find((a) => a.depth == depthInfo.depth);

            if (depthValue?.moisture) {
              oriDepthValues.push(depthValue.moisture);
            }
          });
        });

        probeSetting.probeHistoryDataList.forEach((probeMoistureItem) => {
          const siteHistoryItem = data.find((a) => a.dayNumber == probeMoistureItem.dayNumber);

          if (siteHistoryItem) {
            const moisture = probeMoistureItem.probeMoisture * probeSetting.probeFactor + probeSetting.probeOffset;
            const moistureRaw = ((moisture - probeMoistureItem.soilMoistureLowerTarget) * probeMoistureItem.workingDepth) / 100;

            siteHistoryItem['probe' + index] = Math.round(moisture * 100) / 100;
            siteHistoryItem['probeRaw' + index] = this._unitOfMeasureService.convertFromBase(
              'Fluid Depth',
              unitSizes.normal,
              moistureRaw,
            );

            if (probeSetting.depthInfos.length > 1) {
              let j = 0;

              probeSetting.depthInfos.forEach((depthInfo) => {
                const depthMoistureItem = probeMoistureItem.depthMoistures.find((a) => a.depth == depthInfo.depth);

                if (depthMoistureItem) {
                  const depthMoisture = depthMoistureItem.moisture * probeSetting.probeFactor + probeSetting.probeOffset;
                  const depthMoistureRaw = ((depthMoisture - probeMoistureItem.soilMoistureLowerTarget) * probeMoistureItem.workingDepth) / 100;

                  siteHistoryItem['probe' + index + '_' + j] = Math.round(depthMoisture * 100) / 100;
                  siteHistoryItem['probeRaw' + index + '_' + j] = this._unitOfMeasureService.convertFromBase(
                    'Fluid Depth',
                    unitSizes.normal,
                    depthMoistureRaw,
                  );
                }

                j++;
              });
            }
          }
        });

        index++;
      });
    }
  }

  private plot(valueField: string, title: string, unitType: string, extra = {} as IPlotExtra): AmCharts.AmGraph {
    const translated =
      extra?.translated
        ? title
        : this._languageService.instant(title);

    const label = !!extra['labelTitle'] ? this._languageService.instant(extra['labelTitle']) : translated;

    let id = valueField;

    //Bit of a hack, but converts ids used for the RAW plot to use same show/hide variables in the % moisture plot
    if (id.startsWith('raw')) {
      id = id.replace('raw', 'smb');
    }

    if (id.startsWith('probeRaw')) {
      id = id.replace('Raw', '');
    }

    if (id.endsWith('Raw')) {
      id = id.replace('Raw', 'Percent');
    }

    const offByDefault = ['irrigationBudget', 'irrigationPlanned', 'drainage'];

    if (this.historyChartParams[id] == null) {
      if (offByDefault.indexOf(id) > -1) {
        this.historyChartParams[id] = false;
      } else {
        this.historyChartParams[id] = true;
      }
    }

    const params = {
      id: id,
      valueField: valueField,
      title: translated,
      balloonFunction: this.labelFunc(label, unitType),
      showBalloon: true,
      hidden: !this.historyChartParams[id],
    } as AmCharts.AmGraph;

    Object.keys(extra).forEach((key) => {
      params[key] = extra[key];
    });

    return params;
  }

  private defineSiteHistoryChart() {
    if (this.historyChartParams.soilMoisturePercent == true) {
      const mmAxis = {
        id: 'mmAxis',
        dashLength: 1,
        position: 'left',
        title: this._languageService.instant('AC.SITE.WATER_DIFF', { units: this._fluidDepthNormalUnit }),
        minimum: 0,
      } as AmCharts.ValueAxis;

      const smAxis = {
        id: 'smAxis',
        dashLength: 1,
        position: 'right',
        title: this._languageService.instant('AC.SITE.SOIL_MOISTURE_PERCENT'),
        minimum: 0,
      } as AmCharts.ValueAxis;

      const wiltingPointPercent = this.plot('wiltingPointPercent', 'COMMON.PERMANENT_WILTING_POINT', '%', {
        dashLength: 4,
        lineColor: '#900C3F',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const fieldCapacityPercent = this.plot('fieldCapacityPercent', 'COMMON.FIELD_CAPACITY', '%', {
        dashLength: 8,
        lineColor: '#000000',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const saturationPointPercent = this.plot('saturationPointPercent', 'COMMON.SATURATION_POINT', '%', {
        dashLength: 5,
        lineColor: '#824242',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const smbUpperRange = this.plot('smbUpperTarget', 'COMMON.ABOVE_TARGET_RANGE', '%', {
        fillAlphas: 0.4,
        fillColors: ['#00ffff', '#0084CA'],
        fillToGraph: saturationPointPercent,
        labelTitle: 'COMMON.UPPER_TARGET',
        lineAlpha: 0.1,
        lineColor: '#0084CA',
        markerType: 'square',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const smbTargetRange = this.plot('smbLowerTarget', 'COMMON.TARGET_RANGE', '%', {
        fillAlphas: 0.4,
        fillToGraph: smbUpperRange,
        lineAlpha: 0.1,
        lineColor: '#3FA441',
        markerType: 'square',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const smbLowerTarget = this.plot('smbTargetBase', 'COMMON.BELOW_TARGET_RANGE', '%', {
        fillAlphas: 0.4,
        fillColors: ['#FFC107', '#ffffff'],
        fillToGraph: smbTargetRange,
        lineAlpha: 0.1,
        lineColor: '#FFC107',
        markerType: 'square',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const smb = this.plot('smb', 'AC.SITE.SOIL_MOISTURE_PERCENT', '%', {
        labelTitle: 'AC.SITE.SMB',
        lineColor: '#824242',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const smbOverride = this.plot('smbOverride', 'AC.SITE.SOIL_MOISTURE_OVERRIDE', '%', {
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#824242',
        connect: false,
        lineAlpha: 0,
        valueAxis: smAxis,
      } as IPlotExtra);

      const irrigation = this.plot('irrigation', 'COMMON.IRRIGATION', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#1A237E',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const irrigationPlanned = this.plot('irrigationPlanned', 'COMMON.PLANNED', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#BDB76B',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const irrigationBudget = this.plot('irrigationBudget', 'COMMON.BUDGET', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#008000',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const rainfall = this.plot('rainfall', 'COMMON.WEATHER_READINGS.RAINFALL', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 0.8,
        lineColor: '#2196F3',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const eto = this.plot('eto', 'COMMON.WEATHER_READINGS.ETO', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 0.8,
        lineColor: '#A9548A',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const drainage = this.plot('drainage', 'AC.SITE.DRAINAGE', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: 'red',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      this.siteHistoryChartOption = {
        theme: 'light',
        type: 'serial',
        legend: {
          useGraphSettings: true,
          position: 'top',
          valueAlign: 'left',
          horizontalGap: 5,
          spacing: 1,
          valueFunction: (graphDataItem, graph) => {
            if (graphDataItem.values) {
              const val = graphDataItem.values.value;
              if ((graphDataItem as any).graph.valueAxis.id == 'mmAxis') {
                return val;
              } else {
                if (val) {
                  return val.toFixed(1);
                }
              }
            }
            return '';
          },
          listeners: [
            {
              event: 'hideItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.historyChartParams[legendId] = false;
                this._localStorageService.set('historyChartParams', this.historyChartParams);
              },
            },
            {
              event: 'showItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.historyChartParams[legendId] = true;
                this._localStorageService.set('historyChartParams', this.historyChartParams);
              },
            },
          ],
        } as any as AmCharts.AmLegend,
        valueAxes: [mmAxis, smAxis],
        listeners: [
          {
            event: 'zoomed',
            method: (e) => this.siteHistoryChartZoomed(e),
          },
        ] as Object[],
        startDuration: 0,
        graphs: [
          smbUpperRange,
          smbTargetRange,
          smbLowerTarget,
          wiltingPointPercent,
          fieldCapacityPercent,
          saturationPointPercent,
          rainfall,
          irrigation,
          irrigationPlanned,
          irrigationBudget,
          eto,
          drainage,
          smb,
          smbOverride,
        ],
        plotAreaFillAlphas: 0.1,
        chartScrollbar: {},
        chartCursor: {
          oneBalloonOnly: true,
          pan: false,
          zoomable: false,
          categoryBalloonDateFormat: 'EEE DD MMM, YYYY',
          cursorAlpha: 0.2,
        } as AmCharts.ChartCursor,
        categoryField: 'localeDate',
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0.5,
          parseDates: true,
          axisColor: '#DADADA',
          dateFormats: [
            { period: 'DD', format: 'MMM DD' },
            { period: 'WW', format: 'MMM DD' },
            { period: 'MM', format: 'MMM' },
            { period: 'YYYY', format: 'YYYY' },
          ],
          dashLength: 1,
          minorGridEnabled: true,
        } as AmCharts.CategoryAxis,
      } as any as AmCharts.AmSerialChart;

      // for probe
      if (this.siteProbesHistoryDataList != null) {
        this._colourSetService.resetGradiant();
        for (let i = 0; i < this.siteProbesHistoryDataList.length; i++) {
          const siteProbe = this.siteProbesHistoryDataList[i];
          const probe = this.probes.find((a) => a.name == siteProbe.probeName);
          const probeAvg = probe.nodes.find((a) => a.name == 'COMMON.AVG');

          probeAvg.description = this._languageService.instant(probeAvg.name);

          if (probeAvg.isSelected) {
            const probeGraph = this.plot(
              'probe' + i,
              this._languageService.instant('AC.SITE.OBJ_AVG', { name: siteProbe.probeName }),
              '%',
              {
                valueAxis: smAxis,
                type: 'line',
                lineColor: '#0d47a1',
                lineAlpha: this._colourSetService.getGradiant(),
                connect: false,
                translated: true
              } as IPlotExtra,
            );

            this.siteHistoryChartOption.graphs.push(probeGraph);
          }

          if (siteProbe.depthInfos.length > 1) {
            for (let j = 0; j < siteProbe.depthInfos.length; j++) {
              const depth = this._unitOfMeasureService.convertFromBase(
                'Soil Depth',
                unitSizes.normal,
                siteProbe.depthInfos[j].depth,
              );
              const probeDepth = probe.nodes.find((a) => a.name == String(depth) + ' ' + this._soilDepthNormalUnit);

              if (probeDepth.isSelected) {
                const probeGraph = this.plot(
                  'probe' + i + '_' + j,
                  `${siteProbe.probeName} (${depth} ${this._soilDepthNormalUnit})`,
                  '%',
                  {
                    valueAxis: smAxis,
                    type: 'line',
                    lineColor: '#0d47a1',
                    lineAlpha: this._colourSetService.getGradiant(),
                    connect: false,
                  } as IPlotExtra,
                );

                this.siteHistoryChartOption.graphs.push(probeGraph);
              }
            }
          }
        }
      }
    } else if (this.historyChartParams.soilMoisturePercent == false) {
      const mmAxis = {
        dashLength: 1,
        id: 'mmAxis',
        minimum: 0,
        position: 'left',
        title: this._languageService.instant('AC.SITE.WATER_DIFF', { units: this._fluidDepthNormalUnit }),
      } as AmCharts.ValueAxis;

      const smAxis = {
        dashLength: 1,
        id: 'smAxis',
        position: 'right',
        precision: this.fluidDepthNormalUnitDecimal,
        title: this._languageService.instant('AC.SITE.RAW_FULL_UNITS', { units: this._fluidDepthNormalUnit }),
      } as AmCharts.ValueAxis;

      const wiltingPointRaw = this.plot('wiltingPointRaw', 'COMMON.PERMANENT_WILTING_POINT', UnitTypes.FluidDepth, {
        dashLength: 4,
        lineColor: '#900C3F',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const fieldCapacityRaw = this.plot('fieldCapacityRaw', 'COMMON.FIELD_CAPACITY', UnitTypes.FluidDepth, {
        dashLength: 8,
        lineColor: '#000000',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const saturationPointRaw = this.plot('saturationPointRaw', 'COMMON.SATURATION_POINT', UnitTypes.FluidDepth, {
        dashLength: 5,
        lineColor: '#824242',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const rawUpperRange = this.plot('rawUpperTarget', 'COMMON.ABOVE_TARGET_RANGE', UnitTypes.FluidDepth, {
        fillAlphas: 0.4,
        fillColors: ['#00ffff', '#0084CA'],
        fillToGraph: saturationPointRaw,
        lineAlpha: 0.1,
        lineColor: '#0084CA',
        markerType: 'square',
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const rawTargetRange = this.plot('rawLowerTarget', 'COMMON.TARGET_RANGE', UnitTypes.FluidDepth, {
        fillAlphas: 0.4,
        fillToGraph: rawUpperRange,
        lineAlpha: 0.1,
        lineColor: '#3FA441',
        markerType: 'square',
        showBalloon: false,
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const rawLowerTarget = this.plot('rawTargetBase', 'AC.SETTINGS.TARGET_LOW_RANGE', UnitTypes.FluidDepth, {
        fillAlphas: 0.4,
        fillColors: ['#FFC107', '#ffffff'],
        lineAlpha: 0.1,
        lineColor: '#FFC107',
        markerType: 'square',
        negativeFillAlphas: 0.4,
        negativeFillColors: ['#FFC107', '#ffffff'],
        showBalloon: false,
        type: 'line',
        valueAxis: smAxis,
      } as IPlotExtra);

      const raw = this.plot(
        'raw',
        this._languageService.instant('AC.SITE.RAW_UNITS', { units: this._fluidDepthNormalUnit }),
        UnitTypes.FluidDepth,
        {
          lineColor: '#824242',
          type: 'line',
          valueAxis: smAxis,
        } as IPlotExtra,
      );

      const rawOverride = this.plot('rawOverride', 'AC.SITE.RAW_OVERRIDE', UnitTypes.FluidDepth, {
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#824242',
        connect: false,
        lineAlpha: 0,
        valueAxis: smAxis,
      } as IPlotExtra);

      const irrigation = this.plot('irrigation', 'COMMON.IRRIGATION', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#1A237E',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const irrigationPlanned = this.plot('irrigationPlanned', 'COMMON.PLANNED', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#BDB76B',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const irrigationBudget = this.plot('irrigationBudget', 'COMMON.BUDGET', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: '#008000',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const rainfall = this.plot('rainfall', 'COMMON.WEATHER_READINGS.RAINFALL', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 0.8,
        lineColor: '#2196F3',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const eto = this.plot('eto', 'COMMON.WEATHER_READINGS.ETO', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 0.8,
        lineColor: '#A9548A',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      const drainage = this.plot('drainage', 'AC.SITE.DRAINAGE', UnitTypes.FluidDepth, {
        alphaField: 'alpha',
        clustered: true,
        columnWidth: 0.9,
        fillAlphas: 1,
        lineColor: 'red',
        markerType: 'square',
        type: 'column',
        valueAxis: mmAxis,
      } as IPlotExtra);

      this.siteHistoryChartOption = {
        theme: 'light',
        type: 'serial',
        legend: {
          useGraphSettings: true,
          position: 'top',
          valueAlign: 'left',
          horizontalGap: 5,
          spacing: 1,
          valueFunction: (graphDataItem, graph) => {
            if (graphDataItem.values) {
              const val = (graphDataItem.values as any).value;
              if (val) {
                return val.toFixed(1).toString();
              } else {
                return '';
              }
            }
            return '';
          },
          listeners: [
            {
              event: 'hideItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.historyChartParams[legendId] = false;
                this._localStorageService.set('historyChartParams', this.historyChartParams);
              },
            },
            {
              event: 'showItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.historyChartParams[legendId] = true;
                this._localStorageService.set('historyChartParams', this.historyChartParams);
              },
            },
          ],
        } as any as AmCharts.AmLegend,
        valueAxes: [mmAxis, smAxis],
        startDuration: 0,
        listeners: [
          {
            event: 'zoomed',
            method: (e) => this.siteHistoryChartZoomed(e),
          },
        ] as Object[],
        graphs: [
          rawUpperRange,
          rawTargetRange,
          rawLowerTarget,
          wiltingPointRaw,
          fieldCapacityRaw,
          saturationPointRaw,
          rainfall,
          irrigation,
          irrigationPlanned,
          irrigationBudget,
          eto,
          drainage,
          raw,
          rawOverride,
        ],

        plotAreaFillAlphas: 0.1,
        chartScrollbar: {},
        chartCursor: {
          oneBalloonOnly: true,
          pan: false,
          zoomable: false,
          categoryBalloonDateFormat: 'EEE DD MMM, YYYY',
          cursorAlpha: 0.2,
        } as AmCharts.ChartCursor,
        categoryField: 'localeDate',
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0.5,
          parseDates: true,
          axisColor: '#DADADA',
          dateFormats: [
            { period: 'DD', format: 'MMM DD' },
            { period: 'WW', format: 'MMM DD' },
            { period: 'MM', format: 'MMM' },
            { period: 'YYYY', format: 'YYYY' },
          ],
          dashLength: 1,
        } as AmCharts.CategoryAxis,
      } as any as AmCharts.AmSerialChart; // for probe
      if (this.siteProbesHistoryDataList != null) {
        this._colourSetService.resetGradiant();
        for (let i = 0; i < this.siteProbesHistoryDataList.length; i++) {
          const siteProbe = this.siteProbesHistoryDataList[i];
          const probe = this.probes.find((a) => a.name == siteProbe.probeName);
          const probeAvg = probe.nodes.find((a) => a.name == 'COMMON.AVG');

          probeAvg.description = this._languageService.instant(probeAvg.name);

          if (probeAvg.isSelected) {
            const probeGraph = this.plot(
              'probeRaw' + i,
              this._languageService.instant('AC.SITE.OBJ_AVG', { name: siteProbe.probeName }),
              UnitTypes.FluidDepth,
              {
                valueAxis: smAxis,
                type: 'line',
                lineColor: '#0d47a1',
                lineAlpha: this._colourSetService.getGradiant(),
                connect: false,
              } as IPlotExtra,
            );

            this.siteHistoryChartOption.graphs.push(probeGraph);
          }

          if (siteProbe.depthInfos.length > 1) {
            for (let j = 0; j < siteProbe.depthInfos.length; j++) {
              const depth = this._unitOfMeasureService.convertFromBase(
                'Soil Depth',
                unitSizes.normal,
                siteProbe.depthInfos[j].depth,
              );
              const probeDepth = probe.nodes.find((a) => a.name == depth + ' ' + this._soilDepthNormalUnit);

              if (probeDepth.isSelected) {
                const title = `${siteProbe.probeName} (${depth} ${this._soilDepthNormalUnit})`;
                const probeGraph = this.plot('probeRaw' + i + '_' + j, title, UnitTypes.FluidDepth, {
                  valueAxis: smAxis,
                  type: 'line',
                  lineColor: '#0d47a1',
                  lineAlpha: this._colourSetService.getGradiant(),
                  connect: false,
                } as IPlotExtra);

                this.siteHistoryChartOption.graphs.push(probeGraph);
              }
            }
          }
        }
      }
    }
  }

  private siteHistoryChartZoomed(event) {
    if (this.siteHistoryChart) {
      if (this.isHistoryChartZoomed == false && this.historyChartZoomStartdDate != null) {
        this.siteHistoryChart.zoomToDates(this.historyChartZoomStartdDate, this.historyChartZoomEndDate);
        this.isHistoryChartZoomed = true;
      } else {
        this.isHistoryChartZoomed = true;
        this.historyChartZoomStartdDate = event.startDate.clone();
        this.historyChartZoomEndDate = event.endDate.clone();
      }
    }
  }

  private showSiteHistoryChart() {
    if (this.siteHistoryChartDataProvider?.length) {
      this.siteHistoryChart = AmCharts.makeChart('site-history-chart', this.siteHistoryChartOption) as AmCharts.AmSerialChart;
      this.siteHistoryChart.dataProvider = this.convertedsiteHistoryChartDataProvider;
      this.isHistoryChartZoomed = false; // forces chart to restore previous zoom after calling .validateNow()
      this.addGuideToday(this.siteHistoryChart);
      this.isHistoryChartZoomed = false; // ditto for .validateData()
      this.siteHistoryChart.validateData();
    } else {
      if (this.siteHistoryChart != null) {
        this.siteHistoryChart.clear();
        this.siteHistoryChart = null;
      }
      this._languageService.warning('AC.SITE.MSG.NO_HISTORICAL_DATA');
    }
  }

  private addGuideToday(chart: AmCharts.AmSerialChart) {
    if (!chart) {
      return;
    }

    const guideLine: AmCharts.Guide = new AmCharts.Guide();

    guideLine.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId); // today - now
    guideLine.above = true;
    guideLine.lineColor = '#444444';
    guideLine.lineAlpha = 0.4;
    guideLine.inside = true;
    guideLine.label = DateUtils.Locale.asDateTimeDayAndMonthAndTime(guideLine.date);
    guideLine.labelRotation = 90;
    guideLine.position = 'top';
    guideLine.dashLength = 0;

    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = chart.categoryAxis.guides.length - 1; idx >= 0; idx--) {
        chart.categoryAxis.removeGuide(chart.categoryAxis.guides[idx]);
      }
      chart.categoryAxis.addGuide(guideLine);
    } else {
      //  console.log('check categoryAxis');
    }
    chart.validateNow();
  }

  private getSiteIrrigationHistory(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const siteHistoryFromDayNumber = this._dayNumberService.convertDateToLocaleDayNumber(this.siteHistoryFromData, this.account.timezoneId);
    const params = {
      assetId: this.siteId,
      fromDayNumber: siteHistoryFromDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('site/getSiteIrrigationHistory'), { params: params }).then(
      (response) => {
        this.siteHistoryChartDataProvider = response.data as fuse.siteIrrigationHistoryDto[];
        if (this.siteHistoryChartDataProvider) {
          if (this.siteHistoryChartDataProvider.length) {
            this.siteHistoryChartDataProvider.forEach((item) => {
              item.localeDate = this._dayNumberService.convertDayNumberToLocaleDate(item.dayNumber);
            });

            this.convertedsiteHistoryChartDataProvider = SiteIrrigationHistoryConverter.convert(
              this._unitOfMeasureService,
              this.siteHistoryChartDataProvider,
              unitSizes.normal,
            );
          }
          defer.resolve();
        } else if (this.siteHistoryChartDataProvider == null) {
          this._languageService.whoops();
          defer.reject();
        }
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );

    return defer.promise;
  }

  private getSiteProbeHistoryData(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const siteHistoryFromDayNumber = this._dayNumberService.convertDateToLocaleDayNumber(this.siteHistoryFromData, this.account.timezoneId);
    const averageFromDayNumber = this.adjustedTodayDayNumber - this.goBackDays;
    const params = {
      siteId: this.siteId,
      fromDayNumber: siteHistoryFromDayNumber,
      averageFromDayNumber: averageFromDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('site/getSiteProbeHistoryData'), { params: params }).then(
      (response) => {
        this.siteProbesHistoryDataList = response.data as fuse.siteProbeHistoryDataDto[];
        this.createProbeList();
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );

    return defer.promise;
  }

  private createProbeList() {
    this.probes = [];
    this.siteProbesHistoryDataList.forEach((siteProbe) => {
      const probe = { probeId: siteProbe.probeId, name: siteProbe.probeName, description: siteProbe.probeName, nodes: [] } as ProbeNode;
      const probeAvg = { probeId: siteProbe.probeId, name: 'COMMON.AVG', description: 'COMMON.AVG' } as ProbeNode;

      if (this.selectedProbeId == siteProbe.probeId) {
        probeAvg.isSelected = true;
      }

      probe.nodes.push(probeAvg);

      siteProbe.depthInfos.forEach((depthInfo) => {
        const probeNode = {
          probeId: siteProbe.probeId,
          name:
            this._unitOfMeasureService.convertFromBase('Soil Depth', unitSizes.normal, depthInfo.depth) +
            ' ' +
            this._soilDepthNormalUnit,
        } as ProbeNode;

        probeNode.description = probeNode.name;
        probe.nodes.push(probeNode);
      });

      this.probes.push(probe);
    });

    this.probes.forEach((probe) => {
      if (probe.nodes.every((a) => a.isSelected)) {
        probe.isSelected = true;
      } else {
        probe.isSelected = false;
      }
    });
  }

  public siteHistoryChartModeChanged() {
    this._localStorageService.set('historyChartParams', this.historyChartParams);
    this.defineSiteHistoryChart();
    this.showSiteHistoryChart();
  }

  public refreshSiteHistoryChart() {
    this.historyChartZoomStartdDate = null;
    this.historyChartZoomEndDate = null;

    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getSiteIrrigationHistory());
    promises.push(this.getSiteProbeHistoryData());

    this._q.all(promises).then(() => {
      this.addProbesHistoryTo(this.convertedsiteHistoryChartDataProvider);
      this.showSiteHistoryChart();
    });
  }

  public historyStartDateChanged() {
    this.historyChartZoomStartdDate = null;
    this.historyChartZoomEndDate = null;

    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getSiteIrrigationHistory());
    promises.push(this.getSiteProbeHistoryData());

    this._q.all(promises).then(() => {
      this.addProbesHistoryTo(this.convertedsiteHistoryChartDataProvider);
      this.showSiteHistoryChart();
    });
  }

  public openHelpDialog() {
    this._mdDialog
      .show({
        controller: SiteHistoryChartHelpDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/waterTabs/siteHistoryChartHelp-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(() => {});
  }

  public toggleProbe(probe: ProbeNode) {
    if (probe.isSelected) {
      probe.nodes.forEach((probeNode) => {
        probeNode.isSelected = true;
      });
    } else {
      probe.nodes.forEach((probeNode) => {
        probeNode.isSelected = false;
      });
    }

    this.defineSiteHistoryChart();
    this.showSiteHistoryChart();
  }

  public toggleProbeNode(probe: ProbeNode) {
    if (probe.nodes.every((a) => a.isSelected)) {
      probe.isSelected = true;
    } else {
      probe.isSelected = false;
    }

    this.defineSiteHistoryChart();
    this.showSiteHistoryChart();
  }

  private labelFunc(prefix, unitType) {
    return (graphDataItem, graph) => {
      const val = (graphDataItem.values as any).value;

      return this.valWithUnits(val, prefix, unitType);
    };
  }

  private valWithUnits(val, prefix, unitType) {
    if (val) {
      if (unitType != '%') {
        unitType = this._unitOfMeasureService.getUnitLabel(unitType, unitSizes.normal);
      } else {
        val = val.toFixed(1);
      }

      return `${prefix}: ${val} ${unitType}`;
    } else {
      return '';
    }
  }
}

angular.module('app.account').component('siteWaterHistory', new SiteWaterHistoryComponent());
