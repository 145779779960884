import * as angular from 'angular';
import * as moment from 'moment';
import { ObsSoilMoisture } from 'src/app/_DBContext/ObsSoilMoisture';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class SoilMoistureDialogController extends BaseController {
  public SMOverride: fuse.soilMoisturePrediction;

  public allFields = false;
  private _hasPreviousChanges: boolean;

  private _siteId: number;
  public historyList = [];
  public itemList = [];

  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    item: fuse.soilMoisturePrediction,
    siteId: number,
    hasPreviousChanges: boolean,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._languageService = LanguageService;
    this._dataEntityService = DataEntityService;

    this.entityManager = DataEntityService.manager;
    this.SMOverride = angular.copy(item);

    this._siteId = siteId;
    this._hasPreviousChanges = hasPreviousChanges;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    breeze.EntityQuery.from('getObsSoilMoisture')
      .withParameters({ AssetId: this._siteId })
      .using(this.entityManager)
      .execute()
      .then(this.obsSoilMoistureSuccessCallback.bind(this));
  }

  public saveSMOverride() {
    let theCurId: number;
    const sCurOverrideYMD = this.SMOverride.dayDisplayYMD;

    if (this._hasPreviousChanges) {
      const confirm = this._languageService
        .confirm()
        .title('COMMON.CHANGES_NOT_SAVED')
        .htmlContent('AC.SITE.CHANGES_BEFORE_SM')
        .ok('COMMON.OK');

      this._languageService.show(confirm);
      this._languageService.showSaveFailure('AC.SITE.CHANGES_BEFORE_SM');

      return;
    }

    const saPred: breeze.Predicate = breeze.Predicate.create('AssetId', '==', this._siteId);
    const querySA: breeze.EntityQuery = breeze.EntityQuery.from('ObsSoilMoisture').where(saPred);
    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)
    for (let idx = 0; idx < sa.length; idx++) {
      const smObsEntity: ObsSoilMoisture = sa[idx] as ObsSoilMoisture;
      if (sCurOverrideYMD == smObsEntity.dayDisplayYMD) {
        // compare dayknownas
        if (smObsEntity.TrackDeletedWhen == null) {
          theCurId = smObsEntity.Id;
          smObsEntity.Moisture = this.SMOverride.soilMoisture;
        }
      }
    }

    // or CREATE
    if (theCurId == undefined) {
      const assetType: any = this.entityManager.metadataStore.getEntityType('ObsSoilMoisture');

      // TODO: Day number release. The next line required while development progresses. Delete when not required.
      const d: Date = moment(this.SMOverride.dayDisplayYMD, 'YYYY-MM-DD').toDate();

      const newAssetRec = {
        dayDisplayYMD: this.SMOverride.dayDisplayYMD,
        DateTime: d, // TODO: Day number release. This line required while development progresses. Delete when not required.
        Moisture: this.SMOverride.soilMoisture,
        Depth: 1,
        AssetId: this._siteId,
        QualityFactor: 2,
      };

      const newEntry: any = assetType.createEntity(newAssetRec);
      this.entityManager.addEntity(newEntry);
    }

    this._dataEntityService.saveChanges().then(
      () => {
        this._languageService.success('AC.SITE.MSG.CHANGED_SAVED_REFRESHING');
        this.closeDialog(true);
      },
      (saveFailed) => {
        this._languageService.warning('COMMON.CHANGES_NOT_SAVED_REASON', 'COMMON.WARNING', { reason: saveFailed.message });
        if (saveFailed.entityErrors) {
          saveFailed.entityErrors.map((error) => {
            this._languageService.info(error.errorMessage);
          });
        }
      },
    );
  }

  public deleteSMOverrideConfirm(ev: MouseEvent) {
    if (this._hasPreviousChanges) {
      const confirm = this._languageService
        .confirm()
        .title('COMMON.CHANGES_NOT_SAVED')
        .htmlContent('AC.SITE.CHANGES_BEFORE_SM')
        .ok('COMMON.OK');

      this._languageService.show(confirm);
      this._languageService.showSaveFailure('AC.SITE.CHANGES_BEFORE_SM');

      return;
    }

    const sCurOverrideYMD = this.SMOverride.dayDisplayYMD; //DateTime.toString("yyyy-MM-dd");
    const confirm = this._languageService
      .confirm()
      .title('AC.SITE.MSG.REMOVE_OVERRIDE')
      .htmlContent('AC.SITE.MSG.REMOVE_OVERRIDE_TEXT', { mois: this.SMOverride.soilMoisture, date: sCurOverrideYMD })
      .ariaLabel('AC.SITE.MSG.REMOVE_OVERRIDE')
      .targetEvent(ev)
      .multiple(true) // dont close the SM Override dialog when confirm popsup
      .ok('COMMON.YES')
      .cancel('COMMON.NO');

    this._languageService.show(confirm).then(() => {
      let theLastId: number = undefined;
      const saPred: breeze.Predicate = breeze.Predicate.create('AssetId', '==', this._siteId);
      const querySA: breeze.EntityQuery = breeze.EntityQuery.from('ObsSoilMoisture').where(saPred);
      const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)

      for (let idx = 0; idx < sa.length; idx++) {
        //sa[idx].entityAspect.setDeleted();
        const smObsEntity: ObsSoilMoisture = sa[idx] as ObsSoilMoisture;
        if (sCurOverrideYMD == smObsEntity.dayDisplayYMD) {
          // compare dayknownas
          smObsEntity.entityAspect.setDeleted(); // delete everyting on this day
          theLastId = smObsEntity.Id;
        }
      }

      if (theLastId != undefined) {
        // we got at least one hit
        // and update database (this will apply all outstanding edits)
        if (this.hasDataChanges) {
          this._dataEntityService.saveChanges().then(
            () => {
              this._languageService.success('AC.SITE.MSG.OVERRIDE_REMOVED_REFRESHING');
              this.closeDialog(true);
              //this._state.reload();
            },
            (saveFailed) => {
              this._languageService.warning('COMMON.CHANGES_NOT_SAVED_REASON', 'COMMON.WARNING', { reason: saveFailed.message });

              if (saveFailed.entityErrors) {
                saveFailed.entityErrors.map((error) => {
                  this._languageService.info(error.errorMessage);
                });
              }
            },
          );
        }
      }
    });
  }

  public closeDialog(updated: boolean) {
    this._languageService.hide(updated);
  }

  private obsSoilMoistureSuccessCallback(data: breeze.QueryResult) {
    this.itemList = data.results;

    //  var d = this.convertDate(this.SMOverride.DateTime);
    //  console.log("Selected date: " + d);

    const gArr = [];
    this.historyList = [];

    for (let k = 0; k < this.itemList.length; k++) {
      const deletedsymbol: number = this.itemList[k].TrackDeletedWhen == null ? 1 : 0;

      if (this.itemList[k].dayDisplayYMD == this.SMOverride.dayDisplayYMD) {
        gArr.push({
          id: this.itemList[k].Id,
          moisture: this.itemList[k].Moisture,
          date: this.itemList[k].dayDisplayYMD,
          assetid: this.itemList[k].AssetId,
          deleted: deletedsymbol,
        });
      }
    }
    this.historyList = gArr;
  }
}

angular.module('app.account').controller('SoilMoistureDialogController', SoilMoistureDialogController);
